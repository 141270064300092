var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "p2",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "p2-main"
  }, [_c('div', {
    staticClass: "title",
    attrs: {
      "data-text": _vm.$lang(`THE COPYRIGHT OF MICKEY MOUSE'S FIRST ANIMATED SHORT FILM, 'STEAMBOAT WILLIE', EXPIRED ON JANUARY 1ST OF THIS YEAR. 
THIS MEANS THAT, FROM NOW ON, THE PUBLIC CAN FREELY USE MICKEY'S ORIGINAL CARTOON IMAGE.`)
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang(`THE COPYRIGHT OF MICKEY MOUSE'S FIRST ANIMATED SHORT FILM, 'STEAMBOAT WILLIE', EXPIRED ON JANUARY 1ST OF THIS YEAR. THIS MEANS THAT, FROM NOW ON, THE PUBLIC CAN FREELY USE MICKEY'S ORIGINAL CARTOON IMAGE.`)) + " ")]), _vm._m(0), _vm._m(1)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "img"
  }, [_c('span')]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "label"
  }, [_vm._v("In tribute to 'Steamboat Willie' from 1928, we are launching a unique series of "), _c('i', {
    staticClass: "red"
  }, [_vm._v("1928")]), _vm._v(" meticulously crafted NFTs on the Ethereum blockchain, featuring this iconic character. Mickey Mouse is set to embark on a Web3 journey, bringing this beloved character into the digital age through blockchain technology and NFTs. This collection offers unique interactions and collectibles, ensuring that this iconic figure continues to shine in the new era.`)")]);
}];
export { render, staticRenderFns };