import { createNamespacedHelpers } from "vuex";
const {
  mapState
} = createNamespacedHelpers("wallet");
export default {
  computed: {
    ...mapState(["isMobile"])
  },
  data() {
    return {};
  },
  watch: {},
  methods: {},
  mounted() {},
  created() {
    console.log("this.web3Wallet", this.web3Wallet);
    this.web3Wallet && this.web3Wallet.initWeb3Modal();
  }
};