import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "mHead",
  data() {
    const shareList = [{
      label: this.$lang("TWITTER"),
      value: 0,
      icon: "icon-twitter"
    }, {
      label: this.$lang("TELEGRAM"),
      value: 1,
      icon: "icon-telegram"
    }];
    const menuList = [{
      label: this.$lang("$MICKEY"),
      text: "mickey",
      value: 0
    }, {
      label: this.$lang("MICKY LAUNCHPAD"),
      text: "mickylaunchpad",
      url: "https://launch.mickeysol.com/",
      value: 1
    }, {
      label: this.$lang("TOKENOMICS"),
      text: "tokenomics",
      value: 0
    }, {
      label: this.$lang("ROADMAP"),
      text: "roadmap",
      value: 0
    }];
    return {
      menuList,
      shareList,
      isComing: false,
      isShow: false,
      isBuyShowTmp: false
    };
  },
  watch: {
    isBuyShow: {
      handler(bool) {
        this.isBuyShowTmp = bool;
      },
      deep: true,
      immediate: true
    },
    isBuyShowTmp: {
      handler(bool) {
        this.setIsBuyShow(bool);
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "isBuyShow"])
  },
  methods: {
    ...walletVuex.mapMutations({
      setIsBuyShow: "SETISBUYSHOW"
    }),
    onClick(bool) {
      this.isComing = bool;
    },
    onSkip(item) {
      switch (item.value) {
        case 0:
          open("https://x.com/MickeyOnBase", "_target");
          break;
        case 1:
          open("https://t.me/MickeySteamboatNFT", "_target");
          break;
        case 2:
          open("https://launch.mickeysol.com/0xA825d9B5b09276894AF4666a1bf341b2Fca0f9af", "_target");
          break;
      }
    },
    onMenu(item) {
      if (item === "top") {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      } else {
        const text = document.getElementById(item.text);
        console.dir(text.offsetTop);
        document.body.scrollTop = document.documentElement.scrollTop = text.offsetTop;
        if (item.value == 1) {
          open(item.url, "_target");
        }
      }
    },
    handleScroll() {
      this.$nextTick(() => {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop; // 滚动条偏移量
        this.isShow = scrollTop > 0 ? true : false;
      });
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  }
};