import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "mFooter",
  computed: {
    ...walletVuex.mapState(["isMobile"])
  },
  data() {
    const shareList = [{
      label: this.$lang("TWITTER"),
      value: 0,
      icon: "icon-twitter"
    }, {
      label: this.$lang("TELEGRAM"),
      value: 1,
      icon: "icon-telegram"
    }];
    return {
      shareList
    };
  },
  methods: {
    onSkip(item) {
      switch (item.value) {
        case 0:
          open("https://x.com/MickeyOnBase", "_target");
          break;
        case 1:
          open("https://t.me/MickeySteamboatNFT", "_target");
          break;
      }
    }
  }
};