var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mFooter",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('img', {
    attrs: {
      "src": require('@images/logo.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "share"
  }, _vm._l(_vm.shareList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "share-item",
      on: {
        "click": function ($event) {
          return _vm.onSkip(item);
        }
      }
    }, [_c('svg-icon', {
      attrs: {
        "name": item.icon
      }
    }), _vm._v(" " + _vm._s(item.label) + " ")], 1);
  }), 0)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };