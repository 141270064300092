var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mHead",
    class: [_vm.isMobile && 'wap', _vm.isShow && 'active']
  }, [_c('div', {
    staticClass: "logo",
    on: {
      "click": function ($event) {
        return _vm.onMenu('top');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require('@images/logo.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "menu"
  }, _vm._l(_vm.menuList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "menu-item",
      on: {
        "click": function ($event) {
          return _vm.onMenu(item);
        }
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 0), _c('div', {
    staticClass: "black"
  }), !_vm.isMobile ? _c('div', {
    staticClass: "share"
  }, _vm._l(_vm.shareList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "share-item",
      on: {
        "click": function ($event) {
          return _vm.onSkip(item);
        }
      }
    }, [_c('svg-icon', {
      attrs: {
        "name": item.icon
      }
    }), _vm._v(" " + _vm._s(item.label) + " ")], 1);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.onSkip({
          value: 2
        });
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("BUY $MICKEY")))])])]), _c('pop-buy', {
    attrs: {
      "show": _vm.isBuyShowTmp
    },
    on: {
      "update:show": function ($event) {
        _vm.isBuyShowTmp = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };