var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "p34",
    class: _vm.isMobile && 'wap',
    attrs: {
      "id": "mickylaunchpad"
    }
  }, [_c('div', {
    staticClass: "p4-main"
  }, [_c('div', {
    staticClass: "title",
    attrs: {
      "data-text": _vm.$lang('MICKEY LAUNCHPAD')
    }
  }, [_vm._v(_vm._s(_vm.$lang("MICKEY LAUNCHPAD")))]), !_vm.isMobile ? [_c('div', {
    staticClass: "subtitle",
    attrs: {
      "data-text": _vm.$lang('Ushering in a New Era of Fair and Transparent Token Launches')
    }
  }, [_vm._v(_vm._s(_vm.$lang("Ushering in a New Era of Fair and Transparent Token Launches")))])] : [_c('div', {
    staticClass: "subtitle",
    attrs: {
      "data-text": _vm.$lang('Ushering in a New Era of Fair and')
    }
  }, [_vm._v(_vm._s(_vm.$lang(`Ushering in a New Era of Fair and`)))]), _c('div', {
    staticClass: "subtitle",
    staticStyle: {
      "margin-top": "0"
    },
    attrs: {
      "data-text": _vm.$lang('Transparent Token Launches')
    }
  }, [_vm._v(_vm._s(_vm.$lang(`Transparent Token Launches`)))])], _c('div', {
    staticClass: "brief"
  }, [_vm._v(" " + _vm._s(_vm.$lang(`Mickey Launchpad is dedicated to supporting and incubating new projects, streamlining token launches with transparency, security, and fairness. Our platform ensures easy token deployment, automated liquidity, and unconditional trust, providing a superior cryptocurrency experience. If you have an innovative and exciting idea, let Mickey Launchpad be the catalyst to help it take off!`)) + " ")]), _c('div', {
    staticClass: "con"
  }, [_c('div', {
    staticClass: "img"
  }), _c('div', {
    staticClass: "label"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.$lang("Token Deployment")))]), _c('div', {
    staticClass: "ms"
  }, [_vm._v(_vm._s(_vm.$lang('Deployer pays a fee to deploy the token.')))]), _c('div', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.$lang("Token Minting")))]), _c('div', {
    staticClass: "ms"
  }, [_vm._v(_vm._s(_vm.$lang('Each address can mint a specified amount of tokens by paying a set fee.')))]), _c('div', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.$lang("Minting Limit")))]), _c('div', {
    staticClass: "ms"
  }, [_vm._v(_vm._s(_vm.$lang('Minting ends when 50% of the total supply is minted.')))]), _c('div', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.$lang("Initiating Trading")))]), _c('div', {
    staticClass: "ms"
  }, [_vm._v(_vm._s(_vm.$lang('Deployer can initiate trading after minting ends.')))]), _c('div', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.$lang("Liquidity Pool Addition")))]), _c('div', {
    staticClass: "ms"
  }, [_vm._v(_vm._s(_vm.$lang('Upon trading initiation, the system automatically adds minting fees and the remaining 50% of tokens to the Uniswap V2 liquidity pool.')))]), _c('div', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.$lang("Discarding Control")))]), _c('div', {
    staticClass: "ms"
  }, [_vm._v(_vm._s(_vm.$lang('The system discards control of the liquidity pool and token management to ensure security.')))]), _c('div', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.$lang("Whitelist Minting Settings")))]), _c('div', {
    staticClass: "ms"
  }, [_vm._v(_vm._s(_vm.$lang('Support is now added for whitelist minting. This feature allows project creators to establish a whitelist')))]), _c('div', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.$lang("Whitelist Minting Settings")))]), _c('div', {
    staticClass: "ms"
  }, [_vm._v(_vm._s(_vm.$lang('Support is now added for whitelist minting. This feature allows project creators to establish a whitelist')))])])])], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };