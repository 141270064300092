var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "p4",
    class: _vm.isMobile && 'wap',
    attrs: {
      "id": "tokenomics"
    }
  }, [_c('div', {
    staticClass: "p4-main"
  }, [_c('div', {
    staticClass: "title",
    attrs: {
      "data-text": _vm.$lang('TOKENOMICS')
    }
  }, [_vm._v(_vm._s(_vm.$lang("TOKENOMICS")))]), _c('div', {
    staticClass: "price",
    attrs: {
      "data-text": `1,000,000,000${_vm.$lang(' $MICKEY')}`
    }
  }, [_vm._v("1,000,000,000" + _vm._s(_vm.$lang(" $MICKEY")))]), _c('div', {
    staticClass: "token-info"
  }, [_c('div', {
    staticClass: "i"
  }, [_vm._v(" " + _vm._s(_vm.$lang('TOKEN CONTRACT:')) + "  0xA825d9B5b09276894AF4666a1bf341b2Fca0f9af "), _c('div', {
    directives: [{
      name: "copy",
      rawName: "v-copy",
      value: `0xA825d9B5b09276894AF4666a1bf341b2Fca0f9af`,
      expression: "`0xA825d9B5b09276894AF4666a1bf341b2Fca0f9af`"
    }],
    staticClass: "btn"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-copy"
    }
  })], 1)])]), _c('div', {
    staticClass: "subtitle"
  }, [_vm._v(" " + _vm._s(_vm.$lang(`FAIR LAUNCH. NO TAXES, NO BULLSHIT. IT’S THAT SIMPLE.`)) + " "), _c('br'), _vm._v(" " + _vm._s(_vm.$lang(`LIQUIDITY IS COMPLETELY LOCKED.`)) + " ")]), _vm._m(0)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "con"
  }, [_c('div', {
    staticClass: "img"
  })]);
}];
export { render, staticRenderFns };