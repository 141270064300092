import loading from './myLoading.vue'
import Vue from 'vue'
let instance = null
export default {
    install(Vue) {
        if (!instance) {
            let loadingCompoent = Vue.extend(loading)
            instance = new loadingCompoent({
                el: document.createElement('div')
            })
            document.body.appendChild(instance.$el)
        }
        instance.isShow = false
        let customMethods = {
            show(text) {
                instance.isShow = true
                instance.text = text || Vue.prototype.$lang("Loading")
            },
            hide() {
                instance.isShow = false
            }
        }

        if (!Vue.$loading) {
            Vue.$loading = customMethods
            Vue.prototype.$loading = Vue.$loading
        }
    }
}