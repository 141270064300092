var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "p1",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "p1-main"
  }, [_c('div', {
    staticClass: "title"
  }), _c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.setIsBuyShow(true);
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("MINT NFT")))])]), _c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.onSkip();
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("BUY $MICKEY")))])])])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };