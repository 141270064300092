import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "indexP1",
  computed: {
    ...walletVuex.mapState(["isMobile"])
  },
  data() {
    return {
      isComing: false
    };
  },
  methods: {
    ...walletVuex.mapMutations({
      setIsBuyShow: "SETISBUYSHOW"
    }),
    onClick(bool) {
      this.isComing = bool;
    },
    onSkip() {
      open("https://launch.mickeysol.com/0xA825d9B5b09276894AF4666a1bf341b2Fca0f9af", "_target");
    }
  }
};