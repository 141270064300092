var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "layout",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "layout-main"
  }, [_c('router-view')], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };