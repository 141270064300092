/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./popDialog.vue?vue&type=template&id=468285b2&scoped=true"
import script from "./popDialog.vue?vue&type=script&lang=js"
export * from "./popDialog.vue?vue&type=script&lang=js"
import style0 from "./popDialog.vue?vue&type=style&index=0&id=468285b2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "468285b2",
  null
  
)

export default component.exports