var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    class: _vm.isMobile && 'wap',
    attrs: {
      "width": _vm.isMobile ? `360px` : `600px`,
      "height": "auto"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "myPop myMt",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.$lang('STEAMBOAT WILLIE')) + " ")]), _c('div', {
    staticClass: "myMt-main"
  }, [_c('div', {
    staticClass: "buy-box"
  }, [_c('div', {
    staticClass: "b-btn min",
    on: {
      "click": function ($event) {
        _vm.vNumber = 1;
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang('MIN')))]), _c('div', {
    staticClass: "b-btn sub",
    on: {
      "click": function ($event) {
        return _vm.changeNumer('sub');
      }
    }
  }, [_vm._v("-")]), _c('div', {
    staticClass: "ipt"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.vNumber,
      expression: "vNumber"
    }],
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.vNumber
    },
    on: {
      "keyup": _vm.onkeyUp,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.vNumber = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "b-btn plus",
    on: {
      "click": function ($event) {
        return _vm.changeNumer('plus');
      }
    }
  }, [_vm._v("+")]), _c('div', {
    staticClass: "b-btn max",
    on: {
      "click": function ($event) {
        return _vm.onMax();
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang('MAX')))])]), _c('div', {
    staticClass: "totalprice-box"
  }, [_vm._v(" " + _vm._s(_vm.$lang('TOTAL PRICE')) + ": "), _c('span', [_vm._v(_vm._s(_vm.mintPriceLabel) + "ETH")])]), _c('div', {
    staticClass: "mintbtn-box"
  }, [_vm.leftTime == -1 || _vm.leftTime > 0 ? [_c('div', {
    staticClass: "mint-tip"
  }, [_vm._v("Minting starts at 1:00PM UTC on Feb 1, 2024")])] : [_vm.connect ? _c('div', {
    staticClass: "m-btn mint",
    on: {
      "click": _vm.doMint
    }
  }, [_vm._v(_vm._s(_vm.$lang('MINT')))]) : _c('div', {
    staticClass: "m-btn connect",
    on: {
      "click": _vm.doConnect
    }
  }, [_vm._v(_vm._s(_vm.$lang('CONNECT WALLET')))])]], 2), _c('div', {
    staticClass: "totalsupply-box"
  }, [_vm._v(" " + _vm._s(_vm.$lang('TOTALSUPPLY')) + ": " + _vm._s(_vm.mintLimit) + " ")])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };