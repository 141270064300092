import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers('wallet');
const presaleVuex = createNamespacedHelpers("presale");
export default {
  name: 'webLayout',
  // beforeDestroy() {
  //   this.clearCheckWalletHandle()
  // },
  // destroyed() {
  //   this.clearCheckWalletHandle()
  // },
  created() {
    // this.doConnect()
  },
  watch: {
    // isWallet: {
    //   async handler(bool) {
    //     let walletConnected = localStorage.getItem('WALLET_CONNECTED')
    //     if (walletConnected) {
    //       this.$loading.show()
    //       await this.doConnect()
    //       this.$loading.hide()
    //     } else {
    //       await this.doConnect(false)
    //     }
    //     // if (bool) {
    //     // } else {
    //     //   await this.doConnect(false)
    //     // }
    //   },
    //   immediate: true
    // },

    // async netIdError(bool) {
    //   if (bool && window.ethereum) {
    //     let netId = '0x' + this.web3Config.net_id.toString(16);
    //     try {
    //       await window.ethereum.request({
    //         method: 'wallet_switchEthereumChain',
    //         params: [{ chainId: netId }],
    //       });
    //     } catch (error) {
    //       // This error code indicates that the chain has not been added to MetaMask.
    //       if (error.code === 4902) {
    //         try {
    //           await window.ethereum.request({
    //             method: 'wallet_addEthereumChain',
    //             params: [{
    //               chainId: netId,
    //               chainName: this.web3Config.chain_name,
    //               rpcUrls: [this.web3Config.rpc_url],
    //               nativeCurrency: { name: this.web3Config.chain_unit, symbol: this.web3Config.chain_unit, decimals: 18 },
    //               blockExplorerUrls: [this.web3Config.block_explorer_url]
    //             }],
    //           });
    //         } catch (addError) {
    //           // handle "add" error
    //         }
    //       }
    //       // handle other "switch" errors
    //     }
    //   }
    //   bool && Vue.prototype.$message({
    //     type: 'err',
    //     content: 'NET ID ERROR!'
    //   }).show()
    // },
    // address(newVal, oldVal) {
    //   if (!oldVal) return
    //   let _newVal = newVal.toLowerCase()
    //   let _oldVal = oldVal.toLowerCase()
    //   if (_newVal !== _oldVal) {
    //     this.clearCheckWalletHandle();
    //     this.clearPollingPresaleInfoTimer();
    //     this.pollingPresaleInfo();
    //   }
    // },
  },
  computed: {
    ...walletVuex.mapState(['connect', 'isAddress', 'netIdError', 'address', 'isMobile']),
    ...walletVuex.mapGetters(['isWallet'])
  },
  methods: {
    ...presaleVuex.mapActions(['pollingPresaleInfo']),
    ...presaleVuex.mapMutations({
      clearPollingPresaleInfoTimer: 'CLEARPOLLINGPRESALEINFOTIMER'
    }),
    ...walletVuex.mapMutations({
      clearCheckWalletHandle: 'CLEAR_CHECKWALLETHANDLE'
    }),
    ...walletVuex.mapActions(['doConnect'])
    // 8 U_JgYuEJu9A7LniiJR6LQ2aQmNLL7yZGso7BtC1KCnlvCDcbvz9TXVbdL9cXTlBNSa
    //U_j8dJPb19hNsARIDxSxDfQf4v94Bv52NDB18JDsVHAqblIBgXWQ652hTDKokeQUNQ
  }
};