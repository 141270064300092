var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "p5",
    class: _vm.isMobile && 'wap',
    attrs: {
      "id": "roadmap"
    }
  }, [_c('div', {
    staticClass: "title",
    attrs: {
      "data-text": _vm.$lang('ROADMAP')
    }
  }, [_vm._v(_vm._s(_vm.$lang("ROADMAP")))]), _c('div', {
    staticClass: "p5-main"
  }, [_c('ul', [_c('li', [_vm._m(0), _vm._v(_vm._s(_vm.$lang(`Establish social presence (create Twitter + Telegram+Website+Discord)`)) + " ")]), _c('li', [_vm._m(1), _vm._v(_vm._s(_vm.$lang(`Create community surrounded by Steamboat Willie Collection & $MICKEY lovers`)) + " ")]), _c('li', [_vm._m(2), _vm._v(_vm._s(_vm.$lang(`Mint Steamboat Willie Collection`)) + " ")]), _c('li', [_vm._m(3), _vm._v(_vm._s(_vm.$lang(`$MICKEY Event airdrop`)) + " ")]), _c('li', [_vm._m(4), _vm._v(_vm._s(_vm.$lang(`Coingecko and Coinmarketcap listings`)) + " ")]), _c('li', [_vm._m(5), _vm._v(_vm._s(_vm.$lang(`Mickey launch pad released`)) + " ")]), _c('li', [_vm._m(6), _vm._v(_vm._s(_vm.$lang(`Steamboat Willie $MICKEY global Influence`)) + " ")])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('span', [_c('i', [_vm._v("PHASE 1")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('span', [_c('i', [_vm._v("PHASE 2")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('span', [_c('i', [_vm._v("PHASE 3")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('span', [_c('i', [_vm._v("PHASE 4")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('span', [_c('i', [_vm._v("PHASE 5")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('span', [_c('i', [_vm._v("PHASE 6")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('span', [_c('i', [_vm._v("PHASE "), _c('em', [_vm._v("7")])])]);
}];
export { render, staticRenderFns };