var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "p3",
    class: _vm.isMobile && 'wap',
    attrs: {
      "id": "mickey"
    }
  }, [_c('div', {
    staticClass: "label"
  }, [_c('div', {
    staticClass: "title",
    attrs: {
      "data-text": _vm.$lang('$MICKEY')
    }
  }, [_vm._v(_vm._s(_vm.$lang("$MICKEY")))]), _c('div', {
    staticClass: "ms"
  }, [_vm._v(" " + _vm._s(_vm.$lang(`The first cryptocurrency on the Ethereum blockchain utilizing the public domain image of Mickey Mouse, known as "Steamboat Willie," is the $MICKEY coin. "Steamboat Willie" is the first character designed by Walt Disney and entered the public domain for unlimited use on January 1st, 2024. "Steamboat Willie" combines nostalgia, modern technology, and classic art, all encapsulated in the $MICKEY coin, a symbol of joy, and a timeless character who has warmed our hearts for generations.a token destined to make a significant impact in the Ethereum ecosystem.`)) + " ")])]), _c('div', {
    staticClass: "img"
  })]);
};
var staticRenderFns = [];
export { render, staticRenderFns };