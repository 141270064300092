var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "index"
  }, [_c('div', {
    staticClass: "index-main"
  }, [_c('m-head'), _c('index-p1'), _c('index-p2'), _c('index-p3'), _c('index-p34'), _c('index-p4'), _c('index-p5'), _c('m-footer')], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };