//import web3Wallet from "@web3Wallet/index";
import { createNamespacedHelpers } from "vuex";
import BigNumber from "bignumber.js";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: 'popBuy',
  computed: {
    ...walletVuex.mapState(['connect', 'isAddress', 'netIdError', 'address', 'isMobile', 'isBuyShow']),
    ...walletVuex.mapGetters(['isWallet'])
  },
  data() {
    return {
      vNumber: 1,
      mintPrice: new BigNumber(0.04).times(10 ** 18).toFixed(0),
      mintPriceLabel: 0.04,
      mintTotal: 0,
      mintLimit: 1928,
      isToken: false,
      mintTime: 1706792400,
      nowTime: 0,
      userMint: 0,
      maxMint: 10,
      timeVal: null,
      timeLeftVal: null,
      leftTime: -1
    };
  },
  props: {},
  watch: {
    isWallet: {
      async handler(bool) {
        //验证链接和登录状态
        //const walletConnected = localStorage.getItem("WALLET_CONNECTED");
        // console.log("walletConnected", walletConnected, bool);
        // console.log("****************isWallet***************", walletConnected,!bool);
        // if (walletConnected && !bool) {
        //   await this.doConnect(true);
        // }
        if (bool) {
          const [userMint] = await Promise.all([this.web3Wallet.contract("nft").call("balanceOf", [this.address])]);
          this.userMint = userMint;
        }
      },
      immediate: true,
      deep: true
    },
    "isBuyShow": {
      async handler(bool) {
        if (bool) {
          //验证链接和登录状态
          const walletConnected = localStorage.getItem("WALLET_CONNECTED");
          // console.log("walletConnected", walletConnected, bool);
          console.log("****************isWallet***************", walletConnected);
          if (walletConnected == 'true') {
            await this.doConnect(true);
          }
          this.getInfo();
        } else {
          clearTimeout(this.timeVal);
          clearInterval(this.timeLeftVal);
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...walletVuex.mapActions(['doConnect']),
    async getInfo() {
      clearTimeout(this.timeVal);
      const [mintTotal, nowTime, userMint] = await Promise.all([this.web3Wallet.contract("nftMint").call("mintTotal", []), this.nowTime == 0 ? this.web3Wallet.contract("nftMint").call("getTime", []) : 0, this.address ? this.web3Wallet.contract("nft").call("balanceOf", [this.address]) : 0]);
      this.mintTotal = mintTotal;
      if (this.nowTime == 0) {
        this.nowTime = nowTime;
        this.startLeftTime();
      }
      this.userMint = userMint;
      this.timeVal = setTimeout(() => {
        this.getInfo();
      }, 10000);
    },
    startLeftTime() {
      this.timeLeftVal = setInterval(() => {
        this.nowTime = Number(this.nowTime) + 1;
        console.log(this.nowTime);
        if (this.nowTime >= this.mintTime) {
          this.leftTime = 0;
          clearInterval(this.timeLeftVal);
        } else {
          this.leftTime = this.mintTime - this.nowTime;
        }
      }, 1000);
    },
    changeNumer(type) {
      if (type == 'sub') {
        this.vNumber = Number(this.vNumber) - 1 > 0 ? Number(this.vNumber) - 1 : 1;
      }
      if (type == 'plus') {
        let useNum = this.maxMint - Number(this.userMint);
        if (useNum > Number(this.mintLimit) - Number(this.mintTotal)) {
          useNum = Number(this.mintLimit) - Number(this.mintTotal);
        }
        this.vNumber = Number(this.vNumber) + 1 < useNum ? Number(this.vNumber) + 1 : useNum;
      }
      this.checkPrice();
    },
    onkeyUp(e) {
      if (this.vNumber > Number(this.mintLimit) - Number(this.mintTotal)) {
        this.vNumber = Number(this.mintLimit) - Number(this.mintTotal);
      }
      if (this.vNumber != "" && this.vNumber < 1) {
        this.vNumber = 1;
      }
      this.checkPrice();
    },
    onMax() {
      if (this.maxMint - Number(this.userMint) > Number(this.mintLimit) - Number(this.mintTotal)) {
        this.vNumber = Number(this.mintLimit) - Number(this.mintTotal);
      } else {
        this.vNumber = this.maxMint - Number(this.userMint);
      }
      this.checkPrice();
    },
    checkPrice() {
      this.mintPriceLabel = new BigNumber(this.mintPrice).times(this.vNumber).div(10 ** 18).toFixed(2);
    },
    doMint() {
      if (this.isToken) {} else {
        let priceAll = new BigNumber(this.mintPrice).times(this.vNumber).toFixed(0);
        console.log([this.address, this.vNumber], priceAll);
        this.web3Wallet.contract('nftMint').send('mint', [this.address, this.vNumber], priceAll);
      }
    }
  }
};