var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.isShow ? _c('div', {
    staticClass: "loading",
    style: {
      '--color': _vm.color
    }
  }, [_c('div', {
    staticClass: "loading-main"
  }, [_vm.name == 'atomSpinner' ? _c('div', {
    staticClass: "atom-spinner"
  }, [_c('div', {
    staticClass: "spinner-inner"
  }, _vm._l(3, function (item, index) {
    return _c('div', {
      staticClass: "spinner-line"
    });
  }), 0), _c('div', {
    staticClass: "spinner-circle"
  }, [_vm._v("●")])]) : _vm.name == 'breeding-rhombus-spinner' ? _c('div', {
    staticClass: "breeding-rhombus-spinner"
  }, [_vm._l(8, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "rhombus",
      class: `child-${index + 1}`
    });
  }), _c('div', {
    staticClass: "rhombus big"
  })], 2) : _vm.name == 'looping-rhombuses-spinner' ? _c('div', {
    staticClass: "looping-rhombuses-spinner"
  }, [_c('div', {
    staticClass: "rhombus"
  }), _c('div', {
    staticClass: "rhombus"
  }), _c('div', {
    staticClass: "rhombus"
  })]) : _vm.name == 'self-building-square-spinner' ? _c('div', {
    staticClass: "self-building-square-spinner"
  }, [_c('div', {
    staticClass: "square"
  }), _c('div', {
    staticClass: "square"
  }), _c('div', {
    staticClass: "square"
  }), _c('div', {
    staticClass: "square clear"
  }), _c('div', {
    staticClass: "square"
  }), _c('div', {
    staticClass: "square"
  }), _c('div', {
    staticClass: "square clear"
  }), _c('div', {
    staticClass: "square"
  }), _c('div', {
    staticClass: "square"
  })]) : _vm.name == 'waterfall' ? _c('div', {
    staticClass: "waterfallLoading"
  }, [_vm._m(0)]) : _vm._e(), _c('div', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.text))])])]) : _vm._e();
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "waterfall"
  }, [_c('div'), _c('div'), _c('div'), _c('div'), _c('div')]);
}];
export { render, staticRenderFns };